import { useEffect, useState } from 'react';

import logger from '@/lib/logger';

export const useLocalStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState<any>(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      logger.error('Error writing to localStorage', err);
    }
    setStoredValue(newValue);
  };

  useEffect(() => {
    const listenStorageChange = () => {
      const value = window.localStorage.getItem(keyName)
        ? JSON.parse(window.localStorage.getItem(keyName) ?? '{}')
        : defaultValue;

      setStoredValue(value);
    };
    window.addEventListener('storage', listenStorageChange);
    return () => window.removeEventListener('storage', listenStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue, setValue]);

  return [storedValue, setValue];
};
